import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';

const blocks: { [name: string]: any } = {
  postCodeForm: lazy(() => import('./blocks/service-order-form/PostCodeForm')),
  cart: lazy(() => import('./blocks/cart/Cart')),
  checkout: lazy(() => import('./blocks/checkout/Checkout')),
  login: lazy(() => import('./blocks/user/Login')),
  resetPassword: lazy(() => import('./blocks/user/ResetPassword')),
  locationHome: lazy(() => import('./blocks/location-home/LocationHome')),
  invoices: lazy(() => import('./blocks/invoices/Invoices')),
  locationDocs: lazy(() => import('./blocks/location-docs/LocationDocs')),
  invoicePayment: lazy(() => import('./blocks/invoices/InvoicePayment')),
  createPassword: lazy(() => import('./blocks/user/CreatePassword')),
  reportAnIssue: lazy(() => import('./blocks/report-an-issue/ReportAnIssue')),
  orderHistory: lazy(() => import('./blocks/order-history/OrderHistory')),
  reportingPDF: lazy(() => import('./components/reporting-pdf/ReportingPDF')),
  reportingHome: lazy(() => import('./blocks/reporting-home/ReportingHome')),
};

(() => {
  const scriptSections = document.querySelectorAll('script[rct]');

  [].forEach.call(scriptSections, (scriptSection: HTMLScriptElement) => {
    const blockType = scriptSection.getAttribute('rct');
    const data = scriptSection.textContent;

    if (!blockType || !data) {
      return;
    }

    const Component = blocks[blockType];
    if (Component) {
      scriptSection.textContent = null;
      const section = document.createElement('section');
      section.className = scriptSection.getAttribute('data-class') ?? '';
      section.classList.add('tfm');
      section.classList.add('new-design');
      scriptSection.replaceWith(section);

      const props = JSON.parse(data);
      ReactDOM.createRoot(section).render(
        <React.StrictMode>
          <Provider store={store}>
            <Component {...props} />
          </Provider>
        </React.StrictMode>
      );
    } else {
      return <></>;
    }
  });
})();
